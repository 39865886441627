<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Buttons"
                >
                    <template v-slot:description>
                        import { HbBtn } from 'hummingbird-aviary';
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <!-- delete later -->

        <hb-card title="Accordion Style Buttons + Code" class="mt-4 mb-6">
            <div class="ma-6">
                <hb-data-table
                    :headers="headers2"
                    :items="items2"
                >
                    <template v-slot:item.size="{ item }">
                        <div class="font-weight-medium py-6">{{ item.size }}</div>
                    </template>
                    <template v-slot:item.default="{ item }">
                        <hb-btn accordion @click="doSomething()">{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.disabled="{ item }">
                        <hb-btn accordion @click="doSomething()" disabled>{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.icon="{ item }">
                        <hb-btn accordion @click="doSomething()" :prepend-icon="item.prepend">{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.loading="{ item }">
                        <hb-btn accordion @click="doSomething()" loading>{{ item.text }}</hb-btn>
                    </template>
                </hb-data-table>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-btn accordion @click="doSomething()">Click&lt;/hb-btn>

&lt;hb-btn accordion @click="doSomething()" disabled>Click&lt;/hb-btn>

&lt;hb-btn accordion @click="doSomething()" prepend-icon="mdi-account">Click&lt;/hb-btn>

&lt;hb-btn accordion @click="doSomething()" loading>Click&lt;/hb-btn>
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Primary Buttons + Code" class="mt-4 mb-6">
            <div class="ma-6">
                <hb-data-table
                    :headers="headers"
                    :items="items"
                >
                    <template v-slot:item.size="{ item }">
                        <span class="font-weight-medium">{{ item.size }}</span>
                    </template>
                    <template v-slot:item.default="{ item }">
                        <hb-btn @click="doSomething()" color="primary" :x-small="item.xSmall" :small="item.small" :large="item.large">{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.disabled="{ item }">
                        <hb-btn @click="doSomething()" color="primary" :x-small="item.xSmall" :small="item.small" :large="item.large" disabled>{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.icon="{ item }">
                        <hb-menu>
                            <template v-slot:menu-activator>
                                <hb-btn color="primary" :x-small="item.xSmall" :small="item.small" :large="item.large" :prepend-icon="item.prepend" :append-icon="item.icon">{{ item.text }}</hb-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(option, index) in options"
                                    :key="'option' + index"
                                    @click="''"
                                    :ripple="false"
                                >
                                    <v-list-item-title>{{ option.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:item.loading="{ item }">
                        <hb-btn @click="doSomething()" color="primary" :x-small="item.xSmall" :small="item.small" :large="item.large" loading>{{ item.text }}</hb-btn>
                    </template>
                </hb-data-table>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-btn @click="doSomething()" color="primary" x-small>Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="primary" small>Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="primary">Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="primary" large>Click&lt;/hb-btn>

&lt;hb-btn @click="doSomething()" color="primary" disabled>Click&lt;/hb-btn>

&lt;hb-menu>
    &lt;template v-slot:menu-activator>
        &lt;hb-btn color="primary" prepend-icon="mdi-help-circle" append-icon="mdi-menu-down">Click&lt;/hb-btn>
    &lt;/template>
    &lt;v-list>
        &lt;v-list-item
            v-for="(option, index) in options"
            :key="'option' + index"
            @click="''"
            :ripple="false"
        >
            &lt;v-list-item-title><span v-pre>{{ option.title }}</span>&lt;/v-list-item-title>
        &lt;/v-list-item>
    &lt;/v-list>
&lt;/hb-menu>

&lt;hb-btn @click="doSomething()" color="primary" loading>Click&lt;/hb-btn>
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Secondary Buttons + Code" class="mt-4 mb-6">
            <div class="ma-6">
                <hb-data-table
                    :headers="headers"
                    :items="items"
                >
                    <template v-slot:item.size="{ item }">
                        <span class="font-weight-medium">{{ item.size }}</span>
                    </template>
                    <template v-slot:item.default="{ item }">
                        <hb-btn @click="doSomething()" color="secondary" :x-small="item.xSmall" :small="item.small" :large="item.large">{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.disabled="{ item }">
                        <hb-btn @click="doSomething()" color="secondary" :x-small="item.xSmall" :small="item.small" :large="item.large" disabled>{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.icon="{ item }">
                        <hb-menu>
                            <template v-slot:menu-activator>
                                <hb-btn color="secondary" :x-small="item.xSmall" :small="item.small" :large="item.large" :prepend-icon="item.prepend" :append-icon="item.icon">{{ item.text }}</hb-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(option, index) in options"
                                    :key="index"
                                    @click="''"
                                    :ripple="false"
                                >
                                    <v-list-item-title>{{ option.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:item.loading="{ item }">
                        <hb-btn @click="doSomething()" color="secondary" :x-small="item.xSmall" :small="item.small" :large="item.large" loading>{{ item.text }}</hb-btn>
                    </template>
                </hb-data-table>
            </div>
            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-btn @click="doSomething()" color="secondary" x-small>Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="secondary" small>Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="secondary">Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="secondary" large>Click&lt;/hb-btn>

&lt;hb-btn @click="doSomething()" color="secondary" disabled>Click&lt;/hb-btn>

&lt;hb-menu>
    &lt;template v-slot:menu-activator">
        &lt;hb-btn color="secondary" prepend-icon="mdi-help-circle" append-icon="mdi-menu-down">Click&lt;/hb-btn>
    &lt;/template>
    &lt;v-list>
        &lt;v-list-item
            v-for="(option, index) in options"
            :key="index"
            @click="''"
            :ripple="false"
        >
            &lt;v-list-item-title><span v-pre>{{ option.title }}</span>&lt;/v-list-item-title>
        &lt;/v-list-item>
    &lt;/v-list>
&lt;/hb-menu>

&lt;hb-btn @click="doSomething()" color="secondary" loading>Click&lt;/hb-btn>
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Destructive Buttons + Code" class="mt-4 mb-6">
            <div class="ma-6">
                <hb-data-table
                    :headers="headers"
                    :items="items"
                >
                    <template v-slot:item.size="{ item }">
                        <span class="font-weight-medium">{{ item.size }}</span>
                    </template>
                    <template v-slot:item.default="{ item }">
                        <hb-btn @click="doSomething()" color="destructive" :x-small="item.xSmall" :small="item.small" :large="item.large">{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.disabled="{ item }">
                        <hb-btn @click="doSomething()" color="destructive" :x-small="item.xSmall" :small="item.small" :large="item.large" disabled>{{ item.text }}</hb-btn>
                    </template>
                    <template v-slot:item.icon="{ item }">
                        <hb-menu>
                            <template v-slot:menu-activator>
                                <hb-btn color="destructive" :x-small="item.xSmall" :small="item.small" :large="item.large" :prepend-icon="item.prepend" :append-icon="item.icon">{{ item.text }}</hb-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-for="(option, index) in options"
                                    :key="index"
                                    @click="''"
                                    :ripple="false"
                                >
                                    <v-list-item-title>{{ option.title }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </hb-menu>
                    </template>
                    <template v-slot:item.loading="{ item }">
                        <hb-btn @click="doSomething()" color="destructive" :x-small="item.xSmall" :small="item.small" :large="item.large" loading>{{ item.text }}</hb-btn>
                    </template>
                </hb-data-table>
            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-btn @click="doSomething()" color="destructive" x-small>Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="destructive" small>Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="destructive">Click&lt;/hb-btn>
&lt;hb-btn @click="doSomething()" color="destructive" large>Click&lt;/hb-btn>

&lt;hb-btn @click="doSomething()" color="destructive" disabled>Click&lt;/hb-btn>

&lt;hb-menu>
    &lt;template v-slot:menu-activator>
        &lt;hb-btn color="destructive" prepend-icon="mdi-help-circle" append-icon="mdi-menu-down">Click&lt;/hb-btn>
    &lt;/template>
    &lt;v-list>
        &lt;v-list-item
            v-for="(option, index) in options"
            :key="index"
            @click="''"
            :ripple="false"
        >
            &lt;v-list-item-title><span v-pre>{{ option.title }}</span>&lt;/v-list-item-title>
        &lt;/v-list-item>
    &lt;/v-list>
&lt;/hb-menu>

&lt;hb-btn @click="doSomething()" color="destructive" loading>Click&lt;/hb-btn>
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

    </div>
</template>

<script type="text/babel">

    export default {
        name: "DesignSystemButtons",
        data: function() {
            return {
                headers: [
                    { text: 'Size', value: 'size' },
                    { text: 'Default', value: 'default' },
                    { text: 'Disabled', value: 'disabled' },
                    { text: 'With Prepend + Append Icon', value: 'icon' },
                    { text: 'Loading', value: 'loading' }
                ],
                items: [
                    { size: 'x-small', xSmall: true, small: false, large: false, text: 'Click', prepend: 'mdi-help-circle', icon: 'mdi-menu-down' },
                    { size: 'small', xSmall: false, small: true, large: false, text: 'Click', prepend: 'mdi-help-circle', icon: 'mdi-menu-down' },
                    { size: 'medium (default)', xSmall: false, small: false, large: false, text: 'Click', prepend: 'mdi-help-circle', icon: 'mdi-menu-down' },
                    { size: 'large', xSmall: false, small: false, large: true, text: 'Click', prepend: 'mdi-help-circle', icon: 'mdi-menu-down' },
                ],
                headers2: [
                    { text: 'Size', value: 'size' },
                    { text: 'Default', value: 'default' },
                    { text: 'Disabled', value: 'disabled' },
                    { text: 'With Prepend Icon', value: 'icon' },
                    { text: 'Loading', value: 'loading' }
                ],
                items2: [
                    { size: 'accordion', text: 'Click', prepend: 'mdi-account' },
                ],
                options: [
                    { title: 'Option 1' },
                    { title: 'Option 2' },
                    { title: 'Option 3' },
                    { title: 'Option 4' },
                ],
                propHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'id', type: 'string', default: 'undefined', description: 'Sets the id for the clickable element. Please always set an id for all buttons as we need this for automated testing.' },
                    { name: 'color', type: 'string', default: 'undefined', description: 'Sets the button styling. The possible options are "primary", "secondary", and "destructive". Accordion style buttons are not affected by this prop.' },
                    { name: 'accordion', type: 'string', default: 'undefined', description: 'If changed to true, sets the button styling to accordion look.' },
                    { name: 'x-small', type: 'boolean', default: 'false', description: 'If changed to true, sets the button size to x-small.' },
                    { name: 'small', type: 'boolean', default: 'false', description: 'If changed to true, sets the button size to small.' },
                    { name: 'large', type: 'boolean', default: 'false', description: 'If changed to true, sets the button size to large.' },
                    { name: 'disabled', type: 'boolean', default: 'false', description: 'If changed to true, sets the button to disabled.' },
                    { name: 'prepend-icon', type: 'string', default: 'undefined', description: 'Sets a prepended icon with the mdi-icon code you designate.' },
                    { name: 'append-icon', type: 'string', default: 'undefined', description: 'Sets an appended icon with the mdi-icon code you designate.' },
                    { name: 'append-icon-more-padding', type: 'boolean', default: 'false', description: 'If changed to true, add slightly more padding in between text and appended icon. This is useful because some icons are larger than others and need more padding adding to look nice.' },
                    { name: 'loading', type: 'boolean', default: 'false', description: 'If changed to true, sets a looping loader animation.' },
                    { name: 'block', type: 'boolean', default: 'false', description: 'If changed to true, sets the button to full width of available space.' },
                    { name: 'to', type: 'string || object', default: 'undefined', description: 'Sets the vue route to go to when the button is clicked. For internal links. Accepts a string or object. See vue documentation on how to pass name, paths, params, query, etc...' },
                    { name: 'href', type: 'string', default: 'undefined', description: 'Sets the href value for the button. Usually for external links.' },
                    { name: 'target', type: 'string', default: 'undefined', description: 'Sets the "target" attribute value when "href" is also set. For example, set "target=_blank" to open in a new tab when clicked.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: 'Event for when button is clicked.' },
                ]
            }
        }
    }
</script>

<style scoped>

</style>